var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSlice } from '@reduxjs/toolkit';
import { IStatus } from '../../../@types/status';
import { getMyCalendarWorkTime, createCalendarWorkTime, deleteCalendarWorkTime, updateCalendarWorkTime, getUserWorktime, updateUserWorktime, deleteUserWorktime, createUserWortime, } from './actions';
var initialState = {
    workTimes: { docs: [], meta: {} },
    workTime: {},
    status: IStatus.IDLE,
};
var workTimeSlice = createSlice({
    name: 'workTimes',
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder
            .addCase(getMyCalendarWorkTime.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getMyCalendarWorkTime.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.workTimes = action.payload;
        })
            .addCase(getMyCalendarWorkTime.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(createCalendarWorkTime.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(createCalendarWorkTime.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            var data = payload.data;
            state.workTimes.docs = __spreadArray(__spreadArray([], state.workTimes.docs, true), data, true);
        })
            .addCase(createCalendarWorkTime.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(updateCalendarWorkTime.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(updateCalendarWorkTime.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            var updatedWorktime = payload.data.updatedWorktime;
            state.workTimes.docs = state.workTimes.docs.map(function (wt) {
                return wt._id === updatedWorktime._id ? updatedWorktime : wt;
            });
        })
            .addCase(updateCalendarWorkTime.rejected, function (state, action) {
            state.status = IStatus.FAILED;
        })
            .addCase(deleteCalendarWorkTime.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(deleteCalendarWorkTime.fulfilled, function (state, _a) {
            var payload = _a.payload, meta = _a.meta;
            state.status = IStatus.SUCCEEDED;
            var id = meta.arg.id;
            state.workTimes.docs = state.workTimes.docs.filter(function (item) { return item._id !== id; });
        })
            .addCase(deleteCalendarWorkTime.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(getUserWorktime.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getUserWorktime.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.workTimes = payload;
        })
            .addCase(getUserWorktime.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(updateUserWorktime.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(updateUserWorktime.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            var updatedWorktime = payload.data.updatedWorktime;
            state.workTimes.docs = state.workTimes.docs.map(function (wt) {
                return wt._id === updatedWorktime._id ? updatedWorktime : wt;
            });
        })
            .addCase(updateUserWorktime.rejected, function (state, action) {
            state.status = IStatus.FAILED;
        })
            .addCase(deleteUserWorktime.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(deleteUserWorktime.fulfilled, function (state, _a) {
            var payload = _a.payload, meta = _a.meta;
            state.status = IStatus.SUCCEEDED;
            var id = meta.arg.id;
            state.workTimes.docs = state.workTimes.docs.filter(function (item) { return item._id !== id; });
        })
            .addCase(deleteUserWorktime.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(createUserWortime.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(createUserWortime.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            var data = payload.data;
            state.workTimes.docs = __spreadArray(__spreadArray([], state.workTimes.docs, true), data, true);
        })
            .addCase(createUserWortime.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
    },
});
export var actions = workTimeSlice.actions;
export default workTimeSlice.reducer;
